import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { empty } from '@/libs/tools/helpers'
import { messages } from '@/main'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    /* {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/login',
      name: 'login-session',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full'
      }
    }, */
    {
      path: '/',
      name: 'login2',
      component: () => import('@/views/Login2.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/login2',
      name: 'login1-session',
      component: () => import('@/views/Login2.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/share/:uuid',
      name: 'share-location',
      component: () => import('@/views/Tracker/PublicMap.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/menu/:segment',
      name: 'menu',
      component: () => import('@/views/Menu.vue')
    },
    {
      path: '/trackerAdmin',
      name: 'tracker-admin',
      component: () => import('@/views/Tracker/HeaderAdmin.vue'),
      meta: {
        breadcrumb: [
          { text: 'Dashboard', to: '/trackerAdmin', active: true }
        ],
        pageTitle: 'Dashboard',
        rule: 'operative'
      }
    },
    {
      path: '/dashboard',
      name: 'tracker-dashboard',
      component: () => import('@/views/Tracker/Dashboard.vue'),
      meta: {
        breadcrumb: [
          { text: 'Dashboard', to: '/dashboard', active: true }
        ],
        pageTitle: 'Dashboard',
        rule: 'operative'
      }
    },
    {
      path: '/panic_button/dashboard',
      name: 'panic_button-dashboard',
      component: () => import('@/views/Panic/Dashboard.vue'),
      meta: {
        breadcrumb: [
          { text: 'Dashboard', active: true }
        ],
        pageTitle: 'Dashboard'
      }
    },
    {
      path: '/panic_button/:segment',
      name: 'panic_button',
      component: () => import('@/views/Panic/Header.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/panic_button/sm', active: true }
        ],
        pageTitle: 'Devices'
      }
    },
    {
      path: '/panic_button/:segment',
      name: 'panic_button_map',
      component: () => import('@/views/Panic/Map.vue'),
      meta: {
        breadcrumb: [
          { text: 'MapServiceButton', to: '/panic_button/sm', active: true }
        ],
        pageTitle: 'Devices'
      }
    },
    {
      path: '/panic_button/:segment/detail/:uuid',
      name: 'panic_button-detail',
      component: () => import('@/views/Panic/Detail.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/panic_button/sm' },
          { text: 'DeviceInfo', active: true }
        ],
        pageTitle: 'DeviceInfo'
      }
    },
    {
      path: '/panic_button/:segment/detail/:uuid/history',
      name: 'panic_button-history',
      component: () => import('@/views/Panic/ListAlerts.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'List',
            to: '/panic_button/sm'
          },
          {
            text: 'DeviceDetail',
            to: '/',
            params: 'uuid'
          },
          {
            text: 'HistoryAlerts',
            active: true
          }
        ],
        pageTitle: 'HistoryAlerts'
      },
      beforeEnter: (to, from, next) => {
        to.meta.breadcrumb[0].to = '/panic_button/sm'
        to.meta.breadcrumb[1].to = `/panic_button/sm/detail/${to.params.uuid}`
        next()
      }
    },
    {
      path: '/tracker',
      name: 'tracker',
      component: () => import('@/views/Tracker/Header.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/tracker', active: true }
        ],
        pageTitle: 'Trackers'
      }
    },
    {
      path: '/tracker/detail/:uuid',
      name: 'tracker-detail',
      component: () => import('@/views/Tracker/Detail.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/tracker' },
          { text: 'TrackerInfo', active: true }
        ],
        pageTitle: 'TrackerInfo'
      }
    },
    {
      path: '/panic_button/sm/detail/:uuid/history/:alertUuid',
      name: 'gabinetsLogs',
      component: () => import('@/views/Panic/AlertDetail.vue'),
      meta: {
        breadcrumb: [
          {
            text: 'List',
            to: '/panic_button/sm'
          },
          {
            text: 'DeviceDetail',
            to: '/',
            params: 'uuid'
          },
          {
            text: 'HistoryAlerts',
            to: '/',
            params: 'uuid'
          },
          {
            text: 'AlertDetail',
            active: true
          }
        ],
        pageTitle: 'AlertDetail'
      },
      beforeEnter: (to, from, next) => {
        to.meta.breadcrumb[0].to = '/panic_button/sm'
        to.meta.breadcrumb[1].to = `/panic_button/sm/detail/${to.params.uuid}`
        to.meta.breadcrumb[2].to = `/panic_button/sm/detail/${to.params.uuid}/history`
        next()
      }
    },
    {
      path: '/tracker/config/:uuid',
      name: 'tracker-config',
      component: () => import('@/views/Tracker/Config.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/tracker' },
          { text: 'TrackerInfo', to: '/tracker/detail/', param: 'uuid' },
          { text: 'DeviceConfig', active: true }
        ],
        pageTitle: 'DeviceConfig'
      }
    },
    {
      path: '/tracker/statistics/:uuid',
      name: 'tracker-statistics',
      component: () => import('@/views/Tracker/Statistics.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/tracker' },
          { text: 'TrackerInfo', to: '/tracker/detail/', param: 'uuid' },
          { text: 'Statistics', active: true }
        ],
        pageTitle: 'Statistics'
      }
    },
    {
      path: '/contacts/:uuid',
      name: 'contacts-list',
      component: () => import('@/views/Contact/List.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/tracker' },
          { text: 'TrackerInfo', to: '/tracker/detail/', param: 'uuid' },
          { text: 'Contacts', active: true }
        ],
        pageTitle: 'Contacts'
      }
    },
    {
      path: '/contacts/:uuid/add',
      name: 'contacts-add',
      component: () => import('@/views/Contact/Add.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/tracker' },
          { text: 'TrackerInfo', to: '/tracker/detail/', param: 'uuid' },
          { text: 'Contacts', to: '/contacts/', param: 'uuid' },
          { text: 'NewContact', active: true }
        ],
        pageTitle: 'NewContact'
      }
    },
    {
      path: '/contacts/edit/:trackerid/:uuid',
      name: 'contacts-edit',
      component: () => import('@/views/Contact/Edit.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/tracker' },
          { text: 'TrackerInfo', to: '/tracker/detail/', param: 'trackerid' },
          { text: 'Contacts', to: '/contacts/', param: 'trackerid' },
          { text: 'EditContact', active: true }
        ],
        pageTitle: 'EditContact'
      }
    },
    {
      path: '/contacts/detail/:trackerid/:uuid',
      name: 'contacts-detail',
      component: () => import('@/views/Contact/Detail.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/tracker' },
          { text: 'TrackerInfo', to: '/tracker/detail/', param: 'trackerid' },
          { text: 'Contacts', to: '/contacts/', param: 'trackerid' },
          { text: 'DetailContact', active: true }
        ],
        pageTitle: 'DetailContact'
      }
    },
    {
      path: '/groups/:uuid',
      name: 'groups',
      component: () => import('@/views/Groups/List.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/tracker' },
          { text: 'TrackerInfo', to: '/tracker/detail/', param: 'uuid' },
          { text: 'MyGroups', to: '/groups', active: true }
        ],
        pageTitle: 'MyGroups'
      }
    },
    {
      path: '/groups/:uuid/add',
      name: 'groups-add',
      component: () => import('@/views/Groups/Add.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/tracker' },
          { text: 'TrackerInfo', to: '/tracker/detail/', param: 'uuid' },
          { text: 'MyGroups', to: '/groups/', param: 'uuid' },
          { text: 'NewGroup', to: '/groups/add', active: true }
        ],
        pageTitle: 'NewGroup'
      }
    },
    {
      path: '/groups/edit/:trackerid/:uuid',
      name: 'groups-edit',
      component: () => import('@/views/Groups/Edit.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/tracker' },
          { text: 'TrackerInfo', to: '/tracker/detail/', param: 'trackerid' },
          { text: 'MyGroups', to: '/groups/', param: 'trackerid' },
          { text: 'EditGroup', to: '/groups/edit', active: true }
        ],
        pageTitle: 'EditGroup'
      }
    },
    {
      path: '/groups/detail/:trackerid/:uuid',
      name: 'groups-detail',
      component: () => import('@/views/Groups/Detail.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/tracker' },
          { text: 'TrackerInfo', to: '/tracker/detail/', param: 'trackerid' },
          { text: 'MyGroups', to: '/groups/', param: 'trackerid' },
          { text: 'DetailGroup', to: '/groups/detail', active: true }
        ],
        pageTitle: 'DetailGroup'
      }
    },
    {
      path: '/groups/map/:trackerid/:uuid',
      name: 'groups-map',
      component: () => import('@/views/Groups/Map.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/tracker' },
          { text: 'TrackerInfo', to: '/tracker/detail/', param: 'trackerid' },
          { text: 'MyGroups', to: '/groups/', param: 'trackerid' },
          { text: 'MapGroup', to: '/groups/map', active: true }
        ],
        pageTitle: 'MapGroup'
      }
    },
    {
      path: '/groups/members/:trackerid/:uuid',
      name: 'groups-members',
      component: () => import('@/views/Groups/Members.vue'),
      meta: {
        breadcrumb: [
          { text: 'List', to: '/tracker' },
          { text: 'TrackerInfo', to: '/tracker/detail/', param: 'trackerid' },
          { text: 'MyGroups', to: '/groups/', param: 'trackerid' },
          { text: 'MembersGroup', to: '/groups/map', active: true }
        ],
        pageTitle: 'MembersGroup'
      }
    },
    {
      path: '/requests',
      name: 'requests',
      component: () => import('@/views/Groups/Requests.vue'),
      meta: {
        breadcrumb: [
          { text: 'Requests', to: '/groups/requests', active: true }
        ],
        pageTitle: 'Requests'
      }
    },
    {
      path: '/sharedlinks',
      name: 'shared-links',
      component: () => import('@/views/Tracker/Shares.vue'),
      meta: {
        breadcrumb: [
          { text: 'SharedLinks', to: '/sharedlinks', active: true }
        ],
        pageTitle: 'SharedLinks'
      }
    },
    {
      path: '/tracings',
      name: 'tracings',
      component: () => import('@/views/Tracings/List.vue'),
      meta: {
        breadcrumb: [
          { text: 'Tracings', to: '/tracings', active: true }
        ],
        pageTitle: 'Tracings'
      }
    },
    {
      path: '/tracings/add',
      name: 'tracings-add',
      component: () => import('@/views/Tracings/Add.vue'),
      meta: {
        breadcrumb: [
          { text: 'Tracings', to: '/tracings' },
          { text: 'NewTracing', to: '/tracings/add', active: true }
        ],
        pageTitle: 'NewTracing'
      }
    },
    {
      path: '/tracings/map/:uuid',
      name: 'tracings-map',
      component: () => import('@/views/Tracings/Map.vue'),
      meta: {
        breadcrumb: [
          { text: 'Tracings', to: '/tracings' },
          { text: 'MapTracing', to: '/tracings/map', active: true }
        ],
        pageTitle: 'MapTracing'
      }
    },
    {
      path: '/tracings/members/:uuid',
      name: 'tracings-members',
      component: () => import('@/views/Tracings/Members.vue'),
      meta: {
        breadcrumb: [
          { text: 'Tracings', to: '/tracings' },
          { text: 'MembersTracing', to: '/tracings/members', active: true }
        ],
        pageTitle: 'MembersTracing'
      }
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/Users/List.vue'),
      meta: {
        breadcrumb: [
          { text: 'Users', to: '/users/list', active: true }
        ],
        pageTitle: 'Users'
      }
    },
    {
      path: '/users/add',
      name: 'users-add',
      component: () => import('@/views/Users/Add.vue'),
      meta: {
        breadcrumb: [
          { text: 'UsersList', to: '/users' },
          { text: 'Users', to: '/users/add', active: true }
        ],
        pageTitle: 'NewUser'
      }
    },
    {
      path: '/users/update/:uuid',
      name: 'users-update',
      component: () => import('@/views/Users/Update.vue'),
      meta: {
        breadcrumb: [
          { text: 'Users', to: '/users' },
          { text: 'Edit', to: '/users/update', active: true }
        ],
        pageTitle: 'Users'
      }
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Users/Profile.vue'),
      meta: {
        breadcrumb: [
          { text: 'Profile', to: '/profile', active: true }
        ],
        pageTitle: 'Profile'
      }
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (!empty(store.state.session.AppActiveUser()) && store.state.session.AppActiveUser().token.length > 0) {
    if (to.path === '/' || to.path === '/forgot-password' || (store.state.session.AppActiveUser().userRole !== 'TO' && to.matched.some(record => record.meta.rule === 'operative'))) {
      if (store.state.session.AppActiveUser().assign === 'TB') {
        router.push('/menu/sm')
      } else if (store.state.session.AppActiveUser().assign === 'T') {
        router.push('/tracker')
      } else if (store.state.session.AppActiveUser().assign === 'B') {
        router.push('/panic_button/sm')
      }
    } else {
      messages().then(() => next())
    }
  } else if (to.path === '/' || to.path === '/forgot-password' || to.path.includes('/share/')) {
    messages().then(() => next())
  } else {
    router.push('/')
  }
})

export default router
